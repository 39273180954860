@tailwind components;
@tailwind utilities;

// icons
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

*[class^='material-symbols'] {
  @apply transition-all;
  font-variation-settings:
    'FILL' 0,
    'wght' 300,
    'GRAD' 0,
    'opsz' 24;
}

/* You can add global styles to this file, and also import other style files */
html {
  font-size: 16px;
}
body {
  font-size: 14px;
}
body:has(dialog[open]) {
  overflow: hidden !important;
}
html,
body {
  height: fit-content !important;
  min-height: 100vh;
  text-size-adjust: 100%;
  overflow: unset !important;
  font-family: 'IBM Plex Sans';
  // spacing
  --padding: 20px;
  --gap-xl: 20px;
  --gap-l: 16px;
  --gap-m: 12px;
  --gap-s: 8px;
  --gap-xs: 4px;
  // theme
  --shadow-1: 0 0 0 1px rgba(var(--neutral-1), 0.1) !important;
  --shadow-2: 0 0 0 1px rgba(var(--neutral-1), 0.2) !important;
  --accent-1: 19, 114, 216 !important;
  --accent-1b: 59, 162, 247 !important;
  --header-1: bold 16px/24px 'IBM Plex Sans' !important;
  --header-2: bold 14px/24px 'IBM Plex Sans' !important;
  --body-1: 14px/24px 'IBM Plex Sans' !important;
  --body-2: 12px/16px 'IBM Plex Sans' !important;
  --border-radius: 8px !important;
}
html {
  @apply flex justify-center bg-accent-50;
  body {
    @apply flex;
  }
  // typography
  h1 {
    @apply text-xl leading-6 font-bold;
  }
  h2 {
    @apply text-base font-bold;
  }
  h3 {
    font: var(--header-2);
  }
  p {
    font: var(--body-1);
  }
  h1,
  h2,
  h3,
  p,
  b {
    @apply m-0;
  }
  // divider
  hr {
    margin: var(--gap-m) 0;
    border-top: 1px solid rgba(var(--neutral-1), 0.1);
    border-bottom: unset;
    border-left: unset;
    border-right: unset;
    &.vertical {
      border-top: unset;
      border-left: 1px solid rgba(var(--neutral-1), 0.1);
    }
  }
}

router-outlet {
  @apply hidden;
}

// cards
.card {
  @apply box-border p-5 rounded-lg bg-white flex border border-solid border-black/10 flex-col gap-5 transition-all duration-100 ease-out;
  &.button {
    @apply cursor-pointer;
    &:hover:not(.completed):not(.active) {
      @apply bg-black/5 border-black/20;
    }
  }
  &.completed {
    @apply bg-green-600/5 border-green-600;
    &.button:hover {
      @apply bg-green-600/10;
    }
  }
  &.active {
    @apply bg-accent-600/5 border-accent-600;
    &.button:hover {
      @apply bg-accent-600/10;
    }
  }
  &.disabled {
    @apply pointer-events-none opacity-20;
  }
}

// footer and header

.footer,
.header {
  z-index: 1;
  position: sticky;
  gap: var(--gap-m);
  display: flex;
  align-items: center;
  outline: var(--padding) solid rgb(var(--base-3));
  background: rgb(var(--base-3));
}

.footer {
  justify-content: flex-end;
  bottom: var(--padding);
  margin-top: auto;
}

.header {
  top: var(--padding);
}

.shared-side-bar {
  @apply bg-white p-4 box-border w-72 h-screen sticky top-0 flex flex-col gap-4 overflow-auto;
  box-shadow: var(--shadow-1);
}

// kor
kor-menu-item {
  border: 1px solid transparent;
  &[active] {
    @apply border-accent-600 bg-accent-50;
  }
}

kor-tag {
  max-width: unset;
  &.button {
    cursor: pointer;
    &:not(.active) {
      &:hover {
        background-color: rgba(var(--neutral-1), 0.1);
      }
    }
    &.active {
      @apply border-accent-600 bg-accent-50;
      &:hover {
        @apply bg-accent-100;
      }
    }
  }
}

kor-textarea {
  min-height: fit-content;
}

kor-switch {
  box-shadow: var(--shadow-1);
  background-color: unset;
  kor-switch-item {
    &[active] {
      --shadow-1: 0 0 0 1px rgb(var(--accent-1)) !important;
      box-shadow: var(--shadow-1);
      background: rgba(var(--accent-1), 0.05);
    }
  }
}

.modal-wrapper {
  @apply fixed top-0 left-0 h-full w-full bg-black/40 flex items-center justify-center z-20 box-border;
  backdrop-filter: blur(4px);
  .card {
    max-height: 100%;
    overflow: auto;
    .header {
      top: 0;
      h2 {
        flex: 1;
      }
    }
    .footer {
      bottom: 0;
    }
  }
}

// native elements
button {
  @apply text-sm px-3 py-1 bg-accent-600 rounded-lg font-bold text-white font-sans border-none hover:bg-accent-500 cursor-pointer transition-all flex items-center justify-center gap-1 leading-6 disabled:opacity-50 disabled:pointer-events-none;
  &.secondary {
    @apply bg-black/10 hover:bg-black/15 text-black/90;
  }
  &.tertiary {
    @apply bg-transparent hover:bg-black/5 text-black/90 border border-solid border-black/10;
  }
}

input,
textarea {
  @apply focus:outline-none text-sm border-0 border-b bg-black/5 border-b-black/20 focus:border-b-accent-600 font-sans py-2 px-2 leading-6 placeholder:text-black/60 rounded-sm rounded-b-none;
}

// app classes

// tab item
.blu-tabs {
  @apply flex border-0 border-b border-solid border-black/10;
}
.blu-tab-item {
  @apply border-solid border-0 border-b-2 border-transparent rounded-none bg-transparent hover:bg-transparent text-black/60 hover:text-black/90 p-4;
  &.blu-tab-item-active {
    @apply border-accent-600;
  }
}

.link,
.link:link {
  @apply underline text-accent-600;
}

.link:visited {
  @apply text-violet-600;
}

.link:hover {
  @apply no-underline;
}

.link:active {
  @apply text-red-600;
}
article section {
  pre {
    @apply text-wrap;
  }
  h1,
  h2,
  h3,
  h4 {
    @apply text-balance font-bold;
  }
  h1,
  h2 {
    @apply font-semibold;
  }
  h1 {
    @apply text-3xl text-accent-600 mb-8;
  }
  h2 {
    @apply text-2xl text-black/90 mt-12 mb-6 pt-6 border-0 border-t border-solid border-black/20;
  }
  h3 {
    @apply text-xl text-black/90 mt-6 mb-3;
  }
  h4 {
    @apply text-lg text-black/90 mt-4 mb-2;
  }
  p,
  li,
  ul,
  span {
    @apply text-lg;
  }
  p {
    @apply mt-4 mb-2 leading-relaxed;
  }
  a {
    @apply underline hover:text-accent-500 hover:bg-accent-600/5 transition-all bg-accent-600/10 rounded-md py-0.5 px-1.5 text-accent-600 font-bold;
    &:visited {
      @apply text-violet-600 hover:text-violet-500;
    }
    &:active {
      @apply text-violet-400;
    }
  }
  li {
    @apply leading-relaxed;
  }
  ul,
  ol {
    @apply pl-6 mt-4 marker:text-black/50;
  }
  ul {
    @apply list-disc;
  }
  ol {
    @apply list-decimal marker:text-base;
  }
  strong {
    @apply text-black/90 font-bold;
  }
  img,
  video,
  iframe {
    @apply rounded-xl w-full overflow-hidden border border-solid border-black/20 my-2;
  }
  code {
    @apply block p-4 rounded-xl bg-black/5 text-sm my-2;
  }

  table {
    @apply text-base w-full my-5 border border-solid border-black/10 border-spacing-0 rounded-lg;
    th,
    td {
      @apply border-0 border-b border-solid border-black/10 py-2 px-3 text-left h-6;
    }
    th {
      @apply bg-black/5 border-black/20;
    }
  }
}
